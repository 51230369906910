import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ColorThief from '../../ColorThief';

export const ListingListItemImage = ({ src, className: classNameProp, alt, classNamePrefix = '' }) => {
  const className = classNames(
    `${classNamePrefix}listing-item__hero__container`,
    classNameProp
  )
  const fallbackRGBString = 'rgb(151, 151, 151)'

  return (
    <>
      <ColorThief src={src}>
        {({ data, loading }) => {
          return (
            // TODO: Replace loading gray color with Org Listing primary color
            <div
              className={className}
              style={{
                backgroundColor: data || fallbackRGBString
              }}
            >
              {loading ? (
                <div className="loading-spinner">
                  <i className="icon-spinner" aria-hidden="true"></i>
                </div>
              ) : (
                <img className={classNamePrefix + "listing-item__image"} src={src} alt={alt} />
              )}
            </div>
          );
        }}
      </ColorThief>
    </>
  );
}

ListingListItemImage.propTypes = {
    className: PropTypes.string,
    src: PropTypes.string,
    alt: PropTypes.string,
    classNamePrefix: PropTypes.string
}
