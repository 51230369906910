import { ReactNode, useEffect, useState } from 'react';
import ColorThiefJS from 'colorthief';

const colorThief = new ColorThiefJS();

/**
 * @typedef {[number, number, number]} RGB - Represents an RGB color as a tuple [r, g, b]
 */

/**
 * @typedef {Object} Props
 * @property {string} src - The source URL of the image to extract color from
 * @property {ChildrenFn} children - A function that renders based on color extraction state
 */

/**
 * @typedef {function(Object): ReactNode} ChildrenFn
 * @param {Object} props
 * @param {boolean} props.loading - Whether the color extraction is still loading
 * @param {string} [props.data] - The extracted dominant color as a `rgb(r, g, b)` string
 * @param {RGB} [props.rawData] - The extracted color as an RGB array [r, g, b]
 * @param {string} [props.error] - Error message if extraction fails
 * @returns {ReactNode} The JSX to render
 */

/**
 * Steals dominant color and returns a `data` string of type `rgb(${r}, ${g}, ${b})`. Also, a `rawData` is available as a RBG tuple.
 * @param {Props} props - The component props
 * @returns {ReactNode} The rendered JSX
 */
const ColorThief = ({ src, children }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const [rawData, setRawData] = useState(undefined);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    const image = new Image();
    image.src = src;
    image.crossOrigin = 'anonymous';

    const handleImageLoad = () => {
      try {
        const rawData = colorThief.getColor(image);
        setRawData(rawData);
        const [r, g, b] = rawData;
        setData(`rgb(${r}, ${g}, ${b})`);
      } catch (e) {
        setError('Failed to process image: ', e?.message || JSON.stringify(e));
      }
      setLoading(false);
    };

    const handleError = (e) => {
      setError('Failed to load image: ', e?.message || JSON.stringify(e));
      setLoading(false);
    };

    image.addEventListener('load', handleImageLoad);
    image.addEventListener('error', handleError);

    return () => {
      image.removeEventListener('load', handleImageLoad);
      image.removeEventListener('error', handleError);
    };
  }, [src]);

  return <>{children({ loading, data, rawData, error })}</>;
};

export default ColorThief;
